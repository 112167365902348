import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { green, blue } from '@material-ui/core/colors';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

import api from '~/services/api';
import history from '~/services/history';
import toast from '~/services/toast';
import {
  PEDIDO_BLOQUEIO_TITULO,
  PEDIDO_SITUACAO,
  PEDIDO_TIPO_BLOQUEIO,
  STATUS_MONITOR_PEDIDO,
  TIPO_DOCUMENTO,
  API_BASE_URL,
  API_LOCAL_URL,
  API_VENDAS_URL,
  API_NODE_URL,
  APP_ERROR
} from '~/constants';
import { formatCurrency, formatDate } from '~/utils/format';
import { getApplicationIsOnline } from '~/store/modules/app/app-selectors';
import { PedidoOnlineService } from '~/store/modules/pedido/pedido-service';
import * as monitorActions from '~/store/modules/monitor/monitor-actions';

import Label from '~/components/Label';
import ModalLiberacaoEstoque from '../../modal/ModalLiberacaoEstoque';
import ModalLiberacaoDesconto from '../../modal/ModalLiberacaoDesconto';
import ModalLiberacaoComercial from '../../modal/ModalLiberacaoComercial';
import ModalLiberacaoCredito from '../../modal/ModalLiberacaoCredito';
import ModalVisualizarPedido from '../../modal/ModalVisualizarPedido';
import ModalConfirmacao from '../../modal/ModalConfirmacao';
import { selectorRotina } from '~/store/modules/auth/auth-selectors';
import ModalImprimirPedido from '~/pages/TelaVenda/modal/ModalImprimirPedido';
import { getParametroAsBoolean } from '~/utils/parametros';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  badgeOffline: {
    backgroundColor: '#ccc',
    color: 'white'
  },
  badgeOnline: {
    backgroundColor: '#4caf50',
    color: 'white'
  },
  buttonPrimary: {
    backgroundColor: blue[500],
    '&:hover': {
      backgroundColor: blue[700]
    }
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700]
    }
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -4,
    left: -4,
    zIndex: 1
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

const getSituacaoLabel = situacao => {
  const colors = ['blue', 'green', 'orange', 'red'];
  return <Label color={colors[situacao] ?? 'grey'}>{PEDIDO_SITUACAO[situacao]}</Label>;
};

const PedidoTableRow = React.memo(({ data, isSelected, ...props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { sendingRows, status } = useSelector(state => state.monitor);
  const { terminal } = useSelector(state => state.app);
  const isOnline = useSelector(getApplicationIsOnline());
  const { parametros } = useSelector(state => state.auth);

  const rotinaF010DSA = useSelector(selectorRotina('F040DSA'));
  const [showModalImpressao, setShowModalImpressao] = useState(false);


  const [anchorEl, setAnchorEl] = useState(null);
  const [showModalVisualizarPedido, setShowModalVisualizarPedido] = useState({ show: false, id: null });
  const [showModalLiberacaoEstoque, setShowModalLiberacaoEstoque] = useState({ show: false, id: null });
  const [showModalLiberacaoDesconto, setShowModalLiberacaoDesconto] = useState({ show: false, id: null });
  const [showModalLiberacaoComercial, setShowModalLiberacaoComercial] = useState({ show: false, id: null });
  const [showModalLiberacaoCredito, setShowModalLiberacaoCredito] = useState({ show: false, id: null });
  const [showModalCancelamento, setShowModalCancelamento] = useState({ open: false });

  const handleSelectOneOrder = (event, orderId) => {
    dispatch(monitorActions.selectOneRow(orderId));
  };

  const handleEnviarPedido = data => {
    dispatch(monitorActions.sendPedidoRequest(data));
  };

  const handleLiberacaoBloqueio = documento => {
    const payload = {
      show: true,
      id: documento?.isOffline ? documento?._id : documento?.id,
      isDocumentoOffline: documento?.isOffline ?? false
    };

    if (documento?.bloqueio === PEDIDO_TIPO_BLOQUEIO.BloqueadoEstoque) {
      setShowModalLiberacaoEstoque({ ...payload });
    } else if (documento?.bloqueio === PEDIDO_TIPO_BLOQUEIO.BloqueadoRegraComercial) {
      setShowModalLiberacaoComercial({ ...payload });
    } else if (documento?.bloqueio === PEDIDO_TIPO_BLOQUEIO.BloqueadoRegraDesconto) {
      setShowModalLiberacaoDesconto({ ...payload });
    } else if (
      documento?.bloqueio === PEDIDO_TIPO_BLOQUEIO.BloqueadoInadimplencia ||
      documento?.bloqueio === PEDIDO_TIPO_BLOQUEIO.BloqueadoCredito
    ) {
      setShowModalLiberacaoCredito({ ...payload });
    }
  };

  const handleImprimirPedido = async data => {
    const toastInstance = toast.loading(`Carregando, aguarde...`);
    try {
      const { data: venda } = await api.get(
        data?.isOffline
          ? `${API_LOCAL_URL}/faturamento/pedido/${data?._id}`
          : `${API_BASE_URL}/v1/faturamento/pedido/${data?.id}`
      );

      dispatch(monitorActions.printDocumento(TIPO_DOCUMENTO.PEDIDO, venda));
    } catch (error) {
      dispatch({ type: APP_ERROR, error });
    } finally {
      toastInstance.close();
    }
  };

  const handleEditarPedido = async data => {
    const toastInstance = toast.loading(`Carregando, aguarde...`);
    if(data.situacao === 0) return toast.warning('Não é possível editar com situação fechada!');
    console.log({ data });
    try {
      const { data: pedido } = await api.get(
        data?.isOffline
          ? `${API_LOCAL_URL}/faturamento/pedido/${data?._id}`
          : `${API_BASE_URL}/v1/faturamento/pedido/${data?.id}`
      );

      history.push('/pdv', {
        tipoDocumento: TIPO_DOCUMENTO.PEDIDO,
        venda: { ...pedido, editar: true }
      });
    } catch (error) {
      dispatch({ type: APP_ERROR, error });
    } finally {
      toastInstance.close();
    }
  };

  const handleImportarPedido = async data => {
    const toastInstance = toast.loading(`Carregando, aguarde...`);
    const parametro = getParametroAsBoolean(parametros, 'PF023');
    
    const fetchWithToast = async (promise) => {
      try {
        return await promise;
      } catch (error) {
        dispatch({ type: APP_ERROR, error });
        throw error;
      }
    };

    try {
      const { data: pedido } = await api.get(
        data?.isOffline
          ? `${API_LOCAL_URL}/faturamento/pedido/${data?._id}`
          : `${API_BASE_URL}/v1/faturamento/pedido/${data?.id}`
      );

      if (parametro) {
        const clienteId = pedido?.intCliente?.id;
    
        const { data: creditoCliente } = await fetchWithToast(
          isOnline
            ? api.get(`${API_NODE_URL}/creditoecobranca/cliente/${clienteId}`)
            : api.get(`${API_LOCAL_URL}/integracao/cliente/creditoecobranca/${clienteId}`)
        );
    
        pedido.intCliente = {
          ...pedido.intCliente,
          creditoCliente: {
            saldoLimiteDisponivel: creditoCliente?.saldoLimiteDisponivel ?? 0,
            saldoCreditoDisponivel: creditoCliente?.saldoCreditoDisponivel ?? 0,
            clienteInadimplente: creditoCliente?.inadimplente ?? false
          },
          exibirCreditoCliente: true
        };
      }

      history.push('/pdv', {
        tipoDocumento: TIPO_DOCUMENTO.DOCUMENTOSAIDA,
        venda: {
          ...pedido,
          id: null,
          itens: pedido.itens.map(item => ({
            ...item,
            fatPedidoItemId: item.id,
            id: null,
            itemImportado: true
          })),
          importado: true
        }
      });
    } catch (error) {
      dispatch({ type: APP_ERROR, error });
    } finally {
      toastInstance.close();
    }
  };

  const handleDeletarPedido = async data => {
    const { isConfirmed } = await toast.confirm(
      'Deseja excluir este registro?',
      'Certifique-se de que realmente deseja fazer isso, pois esta ação é irreversível.',
      {
        icon: 'danger',
        confirmButtonText: 'Excluir',
        cancelButtonText: 'Cancelar'
      }
    );
    if (isConfirmed) {
      const toastInstance = toast.loading(`Aguarde...`);
      try {
        await api.delete(
          data?.isOffline
            ? `${API_LOCAL_URL}/faturamento/pedido/${data?._id}`
            : `${API_VENDAS_URL}/v1/faturamento/pedido/${data?.id}`
        );
        dispatch(monitorActions.loadPedidosRequest());
      } catch (error) {
        dispatch({ type: APP_ERROR, error });
      } finally {
        toastInstance.close();
      }
    }
  };

  const handleCancelarPedido = async pedido => {
    setShowModalCancelamento({
      open: true,
      rotina: 'F060PED',
      title: 'Deseja cancelar o pedido selecionado?',
      body:
        'Certifique-se de que realmente deseja fazer isso, pois não será possivel reverter esta ação.',
      options: {
        icon: 'warning',
        confirmButtonText: 'Confirma cancelamento do pedido',
        cancelButtonText: 'Voltar'
      },
      onClose: () => {
        setShowModalCancelamento({ open: false });
      },
      onCloseCallback: async tokenLiberacaoRotina => {
        try {
          setShowModalCancelamento({ open: false });
          const pedidoService = new PedidoOnlineService();
          await pedidoService.cancelarPedidos([pedido.id], tokenLiberacaoRotina);

          dispatch(monitorActions.loadPedidosRequest());

          toast.success('Operação realizada com sucesso!');
        } catch (error) {
          dispatch({ type: APP_ERROR, error });
        }
      }
    });
  };

  const handleClickMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Mui.TableRow
        {...props}
        hover
        selected={isSelected}
        style={{
          background: data?._id ? 'rgba(0,0,0,0.1)' : 'transparent'
        }}
      >
        {status === STATUS_MONITOR_PEDIDO.ABERTOS && (
          <Mui.TableCell padding="checkbox">
            <Mui.Checkbox
              checked={isSelected}
              color="primary"
              value={isSelected}
              onChange={event => handleSelectOneOrder(event, data?.id ?? data._id)}
            />
          </Mui.TableCell>
        )}
        <Mui.TableCell align="center">
          {data?.isOffline && !data?.id ? (
            <Mui.Tooltip title="Offline">
              <Mui.Badge
                classes={{ badge: classes.badgeOffline }}
                overlap="circular"
                badgeContent={''}
              />
            </Mui.Tooltip>
          ) : (
            <Mui.Tooltip title="Online">
              <Mui.Badge
                classes={{ badge: classes.badgeOnline }}
                overlap="circular"
                badgeContent={''}
              />
            </Mui.Tooltip>
          )}
        </Mui.TableCell>
        <Mui.TableCell>
          <Mui.Typography variant="h6">
            {data?.serie}/{data?.numero}
          </Mui.Typography>
        </Mui.TableCell>
        <Mui.TableCell>
          <Mui.Typography variant="h6">{formatDate(new Date(data?.dataEmissao))}</Mui.Typography>
        </Mui.TableCell>
        <Mui.TableCell>{getSituacaoLabel(data?.situacao)}</Mui.TableCell>
        <Mui.TableCell>
          <Label color="grey">{PEDIDO_BLOQUEIO_TITULO[data?.bloqueio]}</Label>
        </Mui.TableCell>

        <Mui.TableCell>
          <Mui.Typography variant="h6" noWrap>
            {data?.clienteNome ?? '-'}
          </Mui.Typography>
        </Mui.TableCell>
        <Mui.TableCell>
          <Mui.Typography variant="h6">{data?.fatVendedor?.nome}</Mui.Typography>
        </Mui.TableCell>
        <Mui.TableCell>
          <Mui.Typography variant="h6">
            {data?.itens
              ?.reduce((arrayAgrupado, item) => {
                const itemExiste = arrayAgrupado?.find(
                  x => x?.id == item?.omsModalidadeExpedicao?.id
                );
                if (itemExiste) {
                  return [...arrayAgrupado];
                } else {
                  return [...arrayAgrupado, item?.omsModalidadeExpedicao];
                }
              }, [])
              ?.map(x => (
                <Label color="grey" style={{ marginLeft: 8 }}>
                  {x?.descricao}
                </Label>
              ))}
          </Mui.Typography>
        </Mui.TableCell>
        <Mui.TableCell align="right">
          <Mui.Typography variant="h6">{formatCurrency(data?.valorTotal)}</Mui.Typography>
        </Mui.TableCell>

        <Mui.TableCell style={{ width: 140 }}>
          <Mui.Box display="flex" flexDirection="row" justifyContent="flex-end">
            {data?.isOffline && !data?.id && isOnline && (
              <div className={classes.root}>
                <div className={classes.wrapper}>
                  <Mui.IconButton size="small" onClick={() => handleEnviarPedido(data)}>
                    <>
                      {sendingRows && sendingRows[data?._id]?.loading ? (
                        <>
                          <MuiIcons.CloudUpload />
                          <Mui.CircularProgress size={38} className={classes.fabProgress} />
                        </>
                      ) : (
                        <MuiIcons.CloudUpload />
                      )}
                    </>
                  </Mui.IconButton>
                </div>
              </div>
            )}
            <Mui.IconButton onClick={() => handleImprimirPedido(data)}>
              <MuiIcons.Print fontSize="small" />
            </Mui.IconButton>

            <Mui.Tooltip title="Mais ações">
              <Mui.IconButton
                disabled={terminal === null}
                aria-controls="simple-menu"
                aria-haspopup="true"
                aria-label={'Mais ações'}
                onClick={e => handleClickMenu(e)}
              >
                <MuiIcons.MoreVert />
              </Mui.IconButton>
            </Mui.Tooltip>
            {Boolean(anchorEl) && (
              <Mui.Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => handleClose()}
              >
                <Mui.MenuItem onClick={() => handleEditarPedido(data)}>
                  <MuiIcons.Edit fontSize="small" /> <span style={{ marginLeft: 12 }}>Editar</span>
                </Mui.MenuItem>

                <Mui.MenuItem onClick={() => handleDeletarPedido(data)}>
                  <MuiIcons.Delete fontSize="small" />{' '}
                  <span style={{ marginLeft: 12 }}>Deletar</span>
                </Mui.MenuItem>

                <Mui.MenuItem onClick={() => setShowModalImpressao(true)}>
                  <MuiIcons.Print fontSize="small" /> <span style={{ marginLeft: 12 }}> Imprimir</span>
                </Mui.MenuItem>
                {data?.bloqueio || data?.situacao === 1 ? <Mui.Divider /> : null}

                {data?.situacao === 1 && rotinaF010DSA && (
                  <Mui.MenuItem onClick={() => handleImportarPedido(data)}>
                    <MuiIcons.ImportExport fontSize="small" />{' '}
                    <span style={{ marginLeft: 12 }}>Exportar</span>
                  </Mui.MenuItem>
                )}

                {data?.cancelado === false && (
                  <Mui.MenuItem onClick={() => handleCancelarPedido(data)}>
                    <MuiIcons.Cancel fontSize="small" />{' '}
                    <span style={{ marginLeft: 12 }}>Cancelar</span>
                  </Mui.MenuItem>
                )}

                {data?.bloqueio ? (
                  <Mui.MenuItem
                    onClick={() => {
                      handleClose();
                      handleLiberacaoBloqueio(data);
                    }}
                  >
                    <MuiIcons.LockOpen fontSize="small" />{' '}
                    <span style={{ marginLeft: 12 }}>Liberar</span>
                  </Mui.MenuItem>
                ) : null}
                <Mui.Divider />
                <Mui.MenuItem
                  onClick={() => {
                    handleClose();
                    setShowModalVisualizarPedido({
                      show: true,
                      id: data?.isOffline ? data?._id : data?.id,
                      isDocumentoOffline: data?.isOffline ?? false
                    });
                  }}
                >
                  <MuiIcons.OpenInNew fontSize="small" />{' '}
                  <span style={{ marginLeft: 12 }}>Visualizar</span>
                </Mui.MenuItem>
                <Mui.MenuItem
                  onClick={() => {
                    handleClose();
                    dispatch(monitorActions.loadHistoricosPedido(data));
                  }}
                >
                  <MuiIcons.OpenInNew fontSize="small" />{' '}
                  <span style={{ marginLeft: 12 }}>Históricos</span>
                </Mui.MenuItem>
              </Mui.Menu>
            )}
          </Mui.Box>
        </Mui.TableCell>
      </Mui.TableRow>

      { showModalImpressao &&
        <ModalImprimirPedido 
          open={showModalImpressao}
          pedido={data}
          onClose={() => {
            setShowModalImpressao(false);
          }}
        />
      }

      {showModalVisualizarPedido?.show && (
        <ModalVisualizarPedido
          id={showModalVisualizarPedido?.id}
          isDocumentoOffline={showModalVisualizarPedido?.isDocumentoOffline}
          open={showModalVisualizarPedido?.show}
          onClose={() => {
            setShowModalVisualizarPedido({ show: false, id: null });
            dispatch(monitorActions.loadPedidosRequest());
          }}
        />
      )}

      {showModalLiberacaoCredito?.show && (
        <ModalLiberacaoCredito
          id={showModalLiberacaoCredito?.id}
          isDocumentoOffline={showModalLiberacaoCredito?.isDocumentoOffline}
          open={showModalLiberacaoCredito?.show}
          onClose={() => {
            setShowModalLiberacaoCredito({ show: false, id: null });
            dispatch(monitorActions.loadPedidosRequest());
          }}
        />
      )}

      {showModalLiberacaoComercial?.show && (
        <ModalLiberacaoComercial
          id={showModalLiberacaoComercial?.id}
          isDocumentoOffline={showModalLiberacaoComercial?.isDocumentoOffline}
          open={showModalLiberacaoComercial?.show}
          onClose={() => {
            setShowModalLiberacaoComercial({ show: false, id: null });
            dispatch(monitorActions.loadPedidosRequest());
          }}
        />
      )}

      {showModalLiberacaoDesconto?.show && (
        <ModalLiberacaoDesconto
          id={showModalLiberacaoDesconto?.id}
          isDocumentoOffline={showModalLiberacaoDesconto?.isDocumentoOffline}
          open={showModalLiberacaoDesconto?.show}
          onClose={() => {
            setShowModalLiberacaoDesconto({ show: false, id: null });
            dispatch(monitorActions.loadPedidosRequest());
          }}
        />
      )}

      {showModalLiberacaoEstoque?.show && (
        <ModalLiberacaoEstoque
          id={showModalLiberacaoEstoque?.id}
          isDocumentoOffline={showModalLiberacaoEstoque?.isDocumentoOffline}
          open={showModalLiberacaoEstoque?.show}
          onClose={() => {
            setShowModalLiberacaoEstoque({ show: false, id: null });
          }}
        />
      )}

      {showModalCancelamento?.open && <ModalConfirmacao {...showModalCancelamento} />}
    </>
  );
});

export default PedidoTableRow;
