import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Paper } from '@material-ui/core';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

import toast from '~/services/toast';
import api from '~/services/api';
import { formatCurrency, formatDate } from '~/utils/format';
import {
  API_BASE_URL,
  API_LOCAL_URL,
  API_NODE_URL,
  APPLICATION_MODE,
  APP_ERROR,
  APP_TERMINAIS,
  FILTRO_DATA,
  STATUS_MONITOR_PEDIDO,
  TIPO_DOCUMENTO
} from '~/constants';
import { DocumentoSaidaOnlineService } from '~/store/modules/documento-saida/documento-saida.service';
import { OrcamentoOnlineService } from '~/store/modules/orcamento/orcamento.service';
import { PedidoOnlineService } from '~/store/modules/pedido/pedido-service';
import { getApplicationIsOnline, isWeb } from '~/store/modules/app/app-selectors';
import { getFilterByData, getFilterNodeByData } from '~/store/modules/monitor/monitor-helper';
import * as pdvActions from '~/store/modules/pdv/pdv-actions';
import * as monitorActions from '~/store/modules/monitor/monitor-actions';

import Searching from '~/components/Searching';
import ListEmpty from '~/components/ListEmpty';
import { calculaValorTotalItem } from '~/store/modules/pdv/pdv-helper';
import { getParametroAsBoolean } from '~/utils/parametros';

const ModalImportarDocumento = ({ type, show, onClose, callback }) => {
  const { parametros } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const location = useLocation();

  const { empresa, filiais } = useSelector(state => state.auth);
  const { terminal } = useSelector(state => state.app);
  const isOnline = useSelector(getApplicationIsOnline());

  const { page, rowsPerPage, rowsPerPageOptions, quantidadeDocumentos } = useSelector(
    state => state.monitor
  );

  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filtroData, setFiltroData] = useState(FILTRO_DATA.HOJE);
  const [filtroFilial, setFiltroFilial] = useState(empresa.id);

  const filtroDataOptions = [
    {
      titulo: 'Hoje',
      filtro: FILTRO_DATA.HOJE
    },
    {
      titulo: 'Ontem',
      filtro: FILTRO_DATA.ONTEM
    },
    {
      titulo: 'Esta semana',
      filtro: FILTRO_DATA.ESTA_SEMANA
    },
    {
      titulo: 'Este mês',
      filtro: FILTRO_DATA.ESTE_MES
    },
    {
      titulo: 'Todos',
      filtro: FILTRO_DATA.TODOS
    }
  ];

  const filtroFiliaisOptions = filiais.map(filial => ({
    titulo: filial.fantasia,
    filtro: filial.id
  }));

  // const [terminais, setTerminais] = useState([]);
  const [documentos, setDocumentos] = useState([]);
  const [totalDocs, setTotalDocs] = useState(null);
  const [ultimosDocumentos, setUltimosDocumentos] = useState([]);
  const [columns] = useState([
    { id: 'terminal', label: 'Terminal', width: 140 },
    { id: 'serieNumero', label: 'Série/Número', width: 120 },
    { id: 'dataEmissao', label: 'Emissão', width: 120 },
    { id: 'cliente', label: 'Cliente' },
    { id: 'operacaoFiscal', label: 'Operação fiscal' },
    { id: 'pagamento', label: 'Pagamento', width: 240 },
    { id: 'vendedor', label: 'Vendedor', width: 240 },
    { id: 'total', alignment: 'right', label: 'Total', width: 140 }
  ]);

  const selectedSomeRows = selectedRows.length > 0 && selectedRows.length < documentos.length;
  const selectedAllRows = selectedRows.length === documentos.length;

  const handleChangePage = async (event, newPage) => {
    dispatch(monitorActions.setPage(newPage));
    let filiaisIds = filiais?.map(x => x.id);
    if (filtroFilial !== null) {
      filiaisIds = [filtroFilial];
    }
    const filtros = [];
    if (filiais?.length > 0) {
      filtros.push({
        chave: 'intEmpresaId',
        valor: filiaisIds,
        operador: 'in'
      });
    }
    if (filtroData !== FILTRO_DATA.TODOS) {
      filtros.push(getFilterNodeByData(filtroData));
    }
    const service = new DocumentoSaidaOnlineService();
    const {
      data: { data }
    } = await service.getImportacao({
      offset: 0,
      limit: rowsPerPage,
      filtros,
      page: newPage
    });
    setDocumentos(data);
  };
  const handleChangeRowsPerPage = async event => {
    let filiaisIds = filiais?.map(x => x.id);
    if (filtroFilial !== null) {
      filiaisIds = [filtroFilial];
    }
    const filtros = [];
    if (filiais?.length > 0) {
      filtros.push({
        chave: 'intEmpresaId',
        valor: filiaisIds,
        operador: 'in'
      });
    }
    if (filtroData !== FILTRO_DATA.TODOS) {
      filtros.push(getFilterNodeByData(filtroData));
    }
    const service = new DocumentoSaidaOnlineService();
    const {
      data: { data }
    } = await service.getImportacao({
      offset: 0,
      limit: parseInt(event.target.value, 10),
      filtros,
      page: 0
    });
    setDocumentos(data);
    dispatch(monitorActions.setRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(monitorActions.setPage(0));
  };

  const getTitle = () => {
    const titles = {
      [TIPO_DOCUMENTO.ORCAMENTO]: 'Importação de orçamento',
      [TIPO_DOCUMENTO.PEDIDO]: 'Importação de pedido',
      [TIPO_DOCUMENTO.DOCUMENTOSAIDA]: 'Importação/cópia de documento'
    };

    return titles[type];
  };

  const getTitleButtonSubmit = () => {
    const titles = {
      [TIPO_DOCUMENTO.ORCAMENTO]: 'Importar vários Orçamentos',
      [TIPO_DOCUMENTO.PEDIDO]: 'Importar vários Pedidos',
      [TIPO_DOCUMENTO.DOCUMENTOSAIDA]: ' Importar/copiar vários documentos'
    };

    return titles[type];
  };

  const loadDocumentos = async () => {
    setLoading(true);
    try {
      let documentosOnline = [];
      // if (isOnline) {
        try {
          let filiaisIds = filiais?.map(x => x.id);
          if (filtroFilial !== null) {
            filiaisIds = [filtroFilial];
          }
          const filtros = [];
          if (filiais?.length > 0) {
            filtros.push({
              chave: 'intEmpresaId',
              valor: filiaisIds,
              operador: 'in'
            });
          }
          if (filtroData !== FILTRO_DATA.TODOS) {
            filtros.push(getFilterNodeByData(filtroData));
          }

          const service = new DocumentoSaidaOnlineService();
          const { data } = await service.getImportacao({
            offset: 0,
            limit: 10,
            filtros
          });

          documentosOnline = [...data?.data?.map(x => ({ ...x, isOffline: false }))];

          setTotalDocs(data.totalCount);

          console.log({ documentosOnline });
        } catch (error) {
          console.error(error);
        }
      // }

      let documentosTerminais = [];

      setDocumentos([...documentosTerminais, ...documentosOnline]);
      setUltimosDocumentos(documentosOnline);
    } catch (error) {
      dispatch({ type: APP_ERROR, error });
    } finally {
      setLoading(false);
    }
  };

  const loadPedidos = async () => {
    setLoading(true);
    try {
      let pedidosOnline = [];
      // if (isOnline) {
      try {
        const service = new PedidoOnlineService();
        const { data } = await service.getAll({
          status: STATUS_MONITOR_PEDIDO.ABERTOS,
          filtroData,
          offset: 0,
          limit: 0
        });

        pedidosOnline = [...data?.pedidos?.map(x => ({ ...x, isOffline: false }))];
      } catch (error) {
        console.error(error);
      }
      // }

      let pedidosTerminais = [];
      if (!isWeb) {
        try {
          const terminais = JSON.parse(sessionStorage.getItem(APP_TERMINAIS) ?? '[]');
          if (terminais) {
            terminais.push({
              identificacaoTerminal: terminal,
              ipLocalTerminal: `${API_LOCAL_URL}`?.replace('http://', '')?.replace(':3335/api', ''),
              active: true
            });
          }

          for (const terminal of terminais) {
            try {
              await api.get(`http://${terminal.ipLocalTerminal}:3335/api`, {
                timeout: 100
              });
            } catch (error) {
              console.log(error?.message);
              if (error?.message === 'timeout of 100ms exceeded') {
                terminais[terminais.indexOf(terminal)].active = false;
              }
            }
          }

          const terminaisAtivos = terminais?.filter(x => x.active);

          const promises = terminaisAtivos?.map(x =>
            api.get(`http://${x.ipLocalTerminal}:3335/api/faturamento/pedido/monitor/abertos`)
          );
          const pedidos = (await Promise.race([...promises]))
            .map((item, index) => {
              return item?.value?.data?.pedidos
                ? item?.value?.data?.pedidos?.map(x => ({
                    ...x,
                    _terminal_importacao: terminaisAtivos[index],
                    isOffline: true
                  }))
                : [];
            })
            .flat();

          if (pedidos) {
            pedidosTerminais = [...pedidos];
          }
        } catch (error) {
          console.error(error);
        }
      }

      setDocumentos([...pedidosTerminais, ...pedidosOnline]);
      setUltimosDocumentos(pedidosOnline);
    } catch (error) {
      dispatch({ type: APP_ERROR, error });
    } finally {
      setLoading(false);
    }
  };

  const loadOrcamentos = async () => {
    setLoading(true);
    try {
      let orcamentosOnline = [];
      // if (isOnline) {
        try {
          const service = new OrcamentoOnlineService();
          const { data } = await service.getAll({
            offset: 0,
            limit: 0,
            filter: [...getFilterByData(filtroData), 'situacao eq 1']
          });

          if (data?.items?.length > 0) {
            orcamentosOnline = [...data?.items?.map(x => ({ ...x, isOffline: false }))];
          }
        } catch (error) {
          console.error(error);
        }
      // }

      let orcamentosTerminais = [];
      if (!isWeb) {
        try {
          const terminais = JSON.parse(sessionStorage.getItem(APP_TERMINAIS) ?? '[]');
          if (terminais) {
            terminais.push({
              identificacaoTerminal: terminal,
              ipLocalTerminal: `${API_LOCAL_URL}`?.replace('http://', '')?.replace(':3335/api', ''),
              active: true
            });
          }

          for (const terminal of terminais) {
            try {
              await api.get(`http://${terminal.ipLocalTerminal}:3335/api`, {
                timeout: 100
              });
            } catch (error) {
              console.log(error?.message);
              if (error?.message === 'timeout of 100ms exceeded') {
                terminais[terminais.indexOf(terminal)].active = false;
              }
            }
          }
          const terminaisAtivos = terminais?.filter(x => x.active);
          const promises = terminaisAtivos?.map(
            async x =>
              await api.get(
                `http://${x.ipLocalTerminal}:3335/api/faturamento/orcamento/situacao/abertos`
              )
          );
          const _orcamentos = (await Promise.allSettled([...promises]))
            .map((item, index) => {
              console.log({ item });
              return item?.value?.data
                ? item?.value?.data?.map(x => ({
                    ...x,
                    _terminal_importacao: terminaisAtivos[index],
                    isOffline: true
                  }))
                : [];
            })
            .flat();

          orcamentosTerminais = [..._orcamentos];
        } catch (error) {
          console.error(error);
        }
      }

      setDocumentos([...orcamentosTerminais, ...orcamentosOnline]);
      setUltimosDocumentos([...orcamentosOnline]);
    } catch (error) {
      dispatch({ type: APP_ERROR, error });
    } finally {
      setLoading(false);
    }
  };

  const loadData = () => {
    if (type === TIPO_DOCUMENTO.PEDIDO) {
      loadPedidos();
    } else if (type === TIPO_DOCUMENTO.ORCAMENTO) {
      loadOrcamentos();
    } else if (type === TIPO_DOCUMENTO.DOCUMENTOSAIDA) {
      loadDocumentos();
    }
  };

  const handleSelectDocumento = (_, id) => {
    setSelectedRows(prevSelected => {
      const _selectedRows = !prevSelected.includes(id)
        ? [...prevSelected, id]
        : [...prevSelected.filter(id => id !== id)];

      return _selectedRows;
    });
  };

  const handleSelectAllDocumentos = event => {
    setSelectedRows(event.target.checked ? documentos.map(item => item?.id ?? item?._id) : []);
  };

  const importarOrcamento = async documentos => {
    dispatch(
      pdvActions.importarOrcamento(documentos, location?.pathname, () => {
        if (onClose) onClose();
      })
    );
  };

  const importarPedido = async documentos => {
    let documentosSelecionados = [];
    const parametro = getParametroAsBoolean(parametros, 'PF023');
    const toastInstance = toast.loading('Carregando documento(s), aguarde ...');

    const fetchWithToast = async (promise) => {
      try {
        return await promise;
      } catch (error) {
        dispatch({ type: APP_ERROR, error });
        throw error;
      }
    };

    try {
      let documentosOnline = [];
      let documentosOffline = documentos?.filter(x => x?.isOffline);

      const documentosOnlineSelecionados = documentos?.filter(x => !x?.isOffline);
      if (isOnline) {
        documentosOnline =
          documentosOnlineSelecionados?.length > 0
            ? (
                await Promise.all([
                  ...documentosOnlineSelecionados?.map(x =>
                    fetchWithToast(api.get(`${API_BASE_URL}/v1/faturamento/pedido/${x.id}`))
                  )
                ])
              ).map(x => x.data)
            : [];
      }
      documentosSelecionados = [...documentosOffline, ...documentosOnline];
        
      if (parametro) {
        const doc = documentosSelecionados?.[0]

        if (doc?.intCliente?.id) {
          const { data: creditoCliente } = await fetchWithToast( isOnline
          ? api.get(`${API_NODE_URL}/creditoecobranca/cliente/${doc?.intCliente?.id}`)
          : api.get(`${API_LOCAL_URL}/integracao/cliente/creditoecobranca/${doc?.intCliente?.id}`))
  
          documentosSelecionados[0].intCliente = {
            ...doc.intCliente,
            creditoCliente: {
              saldoLimiteDisponivel: creditoCliente?.saldoLimiteDisponivel ?? 0,
              saldoCreditoDisponivel: creditoCliente?.saldoCreditoDisponivel ?? 0,
              clienteInadimplente: creditoCliente?.inadimplente ?? false
            },
            exibirCreditoCliente: true
          };
        }
      }
    } catch (error) {
      dispatch({ type: APP_ERROR, error });
    } finally {
      toastInstance.close();
    }

    if (documentosSelecionados?.length === 0) {
      return;
    }

    if (documentosSelecionados?.[0]?.documentoModelo === '65') {
      if (documentosSelecionados?.[0]?.intCliente) {
        const {
          enderecoLogradouro,
          enderecoLogradouroNumero,
          enderecoBairro,
          enderecoUf,
          enderecoIntCidadeId
        } = documentosSelecionados?.[0].intCliente;

        const {
          entregaLogradouro,
          entregaLogradouroNumero,
          entregaBairro,
          entregaUf,
          entregaIntCidadeId
        } = documentosSelecionados?.[0].intCliente;

        const condicaoQualquerCampoEnderecoCliente =
          enderecoLogradouro ||
          enderecoLogradouroNumero ||
          enderecoBairro ||
          enderecoUf ||
          enderecoIntCidadeId;
        const condicaoTodosCamposEnderecoCliente =
          enderecoLogradouro &&
          enderecoLogradouroNumero &&
          enderecoBairro &&
          enderecoUf &&
          enderecoIntCidadeId;
        const condicaoQualquerCampoEnderecoEntretega =
          entregaLogradouro ||
          entregaLogradouroNumero ||
          entregaBairro ||
          entregaUf ||
          entregaIntCidadeId;
        const condicaoTodosCamposEnderecoEntretega =
          entregaLogradouro &&
          entregaLogradouroNumero &&
          entregaBairro &&
          entregaUf &&
          entregaIntCidadeId;

        if (condicaoQualquerCampoEnderecoCliente && !condicaoTodosCamposEnderecoCliente) {
          toast.warning(
            'A NFC-e será gerada mas a informação de endereço do cliente não será adicionada pois os dados estão incompletos.'
          );
        }

        if (condicaoQualquerCampoEnderecoEntretega && !condicaoTodosCamposEnderecoEntretega) {
          toast.warning(
            'A NFC-e será gerada mas a informação de endereço do cliente não será adicionada pois os dados estão incompletos.'
          );
        }
      }

      const entregaUf =
        documentosSelecionados?.[0]?.entregaUf ?? documentosSelecionados?.[0]?.enderecoUf;
      if (entregaUf && empresa.uf !== entregaUf) {
        toast.error(
          'Não é permitido gerar <strong>NFC-e</strong> para destinátario de fora do estado.'
        );
        return;
      }

      if (
        documentosSelecionados?.[0]?.intCliente &&
        documentosSelecionados?.[0]?.intCliente?.tipoContribuinte === 1
      ) {
        toast.error(
          'O cliente do pedido selecionado é contribuinte de <strong>ICMS</strong>, portanto não é possível emitir <strong>NFC-e</strong>. É necessário emitir uma <strong>NF-e</strong>'
        );
        return;
      }
    }

    try {
      if (onClose) onClose();

      if (callback) {
        callback({
          tipoDocumento: TIPO_DOCUMENTO.DOCUMENTOSAIDA,
          venda: {
            // ...documentosSelecionados[0],
            // serie: null,
            // numero: null,
            id: null,
            _id: null,
            intCliente: documentosSelecionados?.[0]?.intCliente ?? null,
            intClienteId: documentosSelecionados?.[0]?.intCliente?.id ?? null,
            clienteNome: documentosSelecionados?.[0]?.clienteNome ?? null,
            clienteCpfCnpj: documentosSelecionados?.[0]?.clienteCpfCnpj ?? null,
            clienteTelefone: documentosSelecionados?.[0]?.clienteTelefone ?? null,
            clienteCelular: documentosSelecionados?.[0]?.clienteCelular ?? null,
            fatProfissional: documentosSelecionados?.[0]?.fatProfissional ?? null,
            fatProfissionalId: documentosSelecionados?.[0]?.fatProfissional?.id ?? null,
            entregaIntClienteEnderecoId:
              documentosSelecionados?.[0]?.entregaIntClienteEnderecoId ?? null,
            entregaCep: documentosSelecionados?.[0]?.entregaCep ?? null,
            entregaLogradouro: documentosSelecionados?.[0]?.entregaLogradouro ?? null,
            entregaLogradouroNumero: documentosSelecionados?.[0]?.entregaLogradouroNumero ?? null,
            entregaComplemento: documentosSelecionados?.[0]?.entregaComplemento ?? null,
            entregaBairro: documentosSelecionados?.[0]?.entregaBairro ?? null,
            entregaIntCidadeId: documentosSelecionados?.[0]?.entregaIntCidadeId ?? null,
            entregaIntCidade: documentosSelecionados?.[0]?.entregaIntCidade ?? null,
            entregaUf: documentosSelecionados?.[0]?.entregaUf ?? null,
            enderecoIntClienteEnderecoId:
              documentosSelecionados?.[0]?.enderecoIntClienteEnderecoId ?? null,
            enderecoCep: documentosSelecionados?.[0]?.enderecoCep ?? null,
            enderecoLogradouro: documentosSelecionados?.[0]?.enderecoLogradouro ?? null,
            enderecoLogradouroNumero: documentosSelecionados?.[0]?.enderecoLogradouroNumero ?? null,
            enderecoComplemento: documentosSelecionados?.[0]?.enderecoComplemento ?? null,
            enderecoBairro: documentosSelecionados?.[0]?.enderecoBairro ?? null,
            enderecoIntCidadeId: documentosSelecionados?.[0]?.enderecoIntCidadeId ?? null,
            enderecoIntCidade: documentosSelecionados?.[0]?.enderecoIntCidade ?? null,
            enderecoUf: documentosSelecionados?.[0]?.enderecoUf ?? null,
            fisOperacao: documentosSelecionados?.[0]?.fisOperacao ?? null,
            fisOperacaoId: documentosSelecionados?.[0]?.fisOperacao?.id ?? null,
            fatCondicaopagamento: documentosSelecionados?.[0]?.fatCondicaopagamento ?? null,
            fatCondicaopagamentoId: documentosSelecionados?.[0]?.fatCondicaopagamento?.id ?? null,
            fatMeiopagamento: documentosSelecionados?.[0]?.fatCondicaopagamento ?? null,
            fatCondicaopagamentoId: documentosSelecionados?.[0]?.fatCondicaopagamento?.id ?? null,
            fatVendedor: documentosSelecionados?.[0]?.fatVendedor ?? null,
            fatVendedorId: documentosSelecionados?.[0]?.fatVendedor?.id ?? null,
            informacaoComplementar: documentosSelecionados?.[0]?.informacaoComplementar ?? null,
            xPed: documentosSelecionados?.[0]?.xPed ?? null,
            xCont: documentosSelecionados?.[0]?.xCont ?? null,
            itens: [
              ...documentosSelecionados.map(documento => [
                ...documento?.itens
                  ?.filter(x => !x.itemCancelado)
                  ?.map(item => ({
                    ...item,
                    itemValorTotal: calculaValorTotalItem(item),
                    _terminal_importacao: documento?._terminal_importacao ?? null,
                    itemImportado: true
                  }))
              ])
            ]
              .flat()
              .map(item => ({
                ...item,
                id: null,
                fatPedidoItemId: item?.id ?? null,
                //offline
                _id: null,
                _parent: null,
                _id_orcamento_item: null,
                _id_pedido_item: item?._id ?? null
              })),
            parcelas:
              documentosSelecionados?.length === 1
                ? documentosSelecionados?.[0]?.parcelas?.map(parcela => ({
                    ...parcela,
                    id: null,
                    //offline
                    _id: null,
                    _parent: null
                  }))
                : [],
            importado: true
          }
        });
      }
    } catch (error) {
      dispatch({ type: APP_ERROR, error });
    }
  };

  const importarDocumento = async documentos => {
    let documentosSelecionados = [];
    const toastInstance = toast.loading('Carregando documento(s), aguarde ...');
    try {
      let documentosOnline = [];
      let documentosOffline = documentos?.filter(x => x?.isOffline);

      const documentosOnlineSelecionados = documentos?.filter(x => !x?.isOffline);
      if (isOnline) {
        documentosOnline =
          documentosOnlineSelecionados?.length > 0
            ? (
                await Promise.all([
                  ...documentosOnlineSelecionados?.map(x =>
                    api.get(
                      `${API_BASE_URL}/v1/faturamento/documentosaida/filiaiscompartilhadas/${x.id}`
                    )
                  )
                ])
              ).map(x => x.data)
            : [];
      }
      documentosSelecionados = [...documentosOffline, ...documentosOnline];
    } catch (error) {
      dispatch({ type: APP_ERROR, error });
    } finally {
      toastInstance.close();
    }

    if (documentosSelecionados?.length === 0) {
      return;
    }

    documentosSelecionados.forEach(doc => {
      doc.itens.forEach(item => {
        if (!item.saldoEstoque) {
          return (item.estoqueInsuficiente = true);
        }
        const estoqueDisponivel =
          item.saldoEstoque?.reduce((tot, item) => (tot += item.estoqueReal), 0) -
          item.saldoEstoque?.reduce((tot, item) => (tot += item.estoqueReservado), 0);
        if (estoqueDisponivel < item.itemQuantidade) {
          item.estoqueInsuficiente = true;
        }
      });
    });

    const itens = [
      ...documentosSelecionados
        ?.map(documento =>
          documento?.itens ? documento?.itens?.filter(x => !x.itemCancelado) : []
        )
        .flat()
    ];
    const produtos = (
      await Promise.all(
        itens?.map(x => api.get(`${API_BASE_URL}/v1/estoque/produto/${x.produtoEstProdutoId}`))
      )
    )?.map(x => x.data);

    try {
      if (onClose) onClose();

      if (callback) {
        callback({
          tipoDocumento: TIPO_DOCUMENTO.DOCUMENTOSAIDA,
          venda: {
            id: null,
            _id: null,
            intCliente: documentosSelecionados?.[0]?.intCliente ?? null,
            intClienteId: documentosSelecionados?.[0]?.intCliente?.id ?? null,
            clienteNome: documentosSelecionados?.[0]?.clienteNome ?? null,
            clienteCpfCnpj: documentosSelecionados?.[0]?.clienteCpfCnpj ?? null,
            clienteTelefone: documentosSelecionados?.[0]?.clienteTelefone ?? null,
            clienteCelular: documentosSelecionados?.[0]?.clienteCelular ?? null,
            entregaIntClienteEnderecoId:
              documentosSelecionados?.[0]?.entregaIntClienteEnderecoId ?? null,
            entregaCep: documentosSelecionados?.[0]?.entregaCep ?? null,
            entregaLogradouro: documentosSelecionados?.[0]?.entregaLogradouro ?? null,
            entregaLogradouroNumero: documentosSelecionados?.[0]?.entregaLogradouroNumero ?? null,
            entregaComplemento: documentosSelecionados?.[0]?.entregaComplemento ?? null,
            entregaBairro: documentosSelecionados?.[0]?.entregaBairro ?? null,
            entregaIntCidadeId: documentosSelecionados?.[0]?.entregaIntCidadeId ?? null,
            entregaIntCidade: documentosSelecionados?.[0]?.entregaIntCidade ?? null,
            entregaUf: documentosSelecionados?.[0]?.entregaUf ?? null,
            enderecoIntClienteEnderecoId:
              documentosSelecionados?.[0]?.enderecoIntClienteEnderecoId ?? null,
            enderecoCep: documentosSelecionados?.[0]?.enderecoCep ?? null,
            enderecoLogradouro: documentosSelecionados?.[0]?.enderecoLogradouro ?? null,
            enderecoLogradouroNumero: documentosSelecionados?.[0]?.enderecoLogradouroNumero ?? null,
            enderecoComplemento: documentosSelecionados?.[0]?.enderecoComplemento ?? null,
            enderecoBairro: documentosSelecionados?.[0]?.enderecoBairro ?? null,
            enderecoIntCidadeId: documentosSelecionados?.[0]?.enderecoIntCidadeId ?? null,
            enderecoIntCidade: documentosSelecionados?.[0]?.enderecoIntCidade ?? null,
            enderecoUf: documentosSelecionados?.[0]?.enderecoUf ?? null,
            fisOperacao: documentosSelecionados?.[0]?.fisOperacao ?? null,
            fisOperacaoId: documentosSelecionados?.[0]?.fisOperacao?.id ?? null,
            fatCondicaopagamento: documentosSelecionados?.[0]?.fatCondicaopagamento ?? null,
            fatCondicaopagamentoId: documentosSelecionados?.[0]?.fatCondicaopagamento?.id ?? null,
            fatMeiopagamento: documentosSelecionados?.[0]?.fatCondicaopagamento ?? null,
            fatCondicaopagamentoId: documentosSelecionados?.[0]?.fatCondicaopagamento?.id ?? null,
            fatVendedor: documentosSelecionados?.[0]?.fatVendedor ?? null,
            fatVendedorId: documentosSelecionados?.[0]?.fatVendedor?.id ?? null,
            itens: itens.map((item, index) => ({
              ...item,
              id: null,
              omsModalidadeExpedicao: null,
              omsModalidadeExpedicaoId: null,
              index,
              fatPedidoItemId: null,
              fatOrcamentoItemId: null,
              produtoEstProduto: produtos?.find(x => x.id === item.produtoEstProdutoId),
              produtoEstArmazem: produtos?.find(x => x.id === item.produtoEstProdutoId)
                ?.estProdutoImposto[0]?.estoqueEstArmazem,
              produtoEstArmazemId: produtos?.find(x => x.id === item.produtoEstProdutoId)
                ?.estProdutoImposto[0]?.estoqueEstArmazemId,
              //offline
              _id: null,
              _parent: null,
              _id_orcamento_item: null,
              _id_pedido_item: null
            })),
            parcelas:
              documentosSelecionados?.length === 1
                ? documentosSelecionados?.[0]?.parcelas?.map(parcela => ({
                    ...parcela,
                    id: null,
                    _id: null,
                    _parent: null
                  }))
                : [],
            importado: true
          }
        });
      }
    } catch (error) {
      dispatch({ type: APP_ERROR, error });
    }
  };

  // Função auxiliar para buscar documentos online
  async function buscarDocumentosOnline(numeroDocumento) {
    if (!isOnline) return [];

    const endpointPedido = `${API_BASE_URL}/v1/faturamento/pedido/?$select=id&$filter=(numero eq ${numeroDocumento}) and (situacao eq 1)`;
    const endpointDocumentoSaida = `${API_NODE_URL}/faturamento/documento-saida/ids?$filter=${numeroDocumento}&data=${filtroData}&empresa=${filtroFilial}`;
    const endpointOrcamento = `${API_BASE_URL}/v1/faturamento/orcamento/?$select=id&$filter=(numero eq ${numeroDocumento})`;

    let url;
    if (type === TIPO_DOCUMENTO.PEDIDO) {
      url = endpointPedido;
    } else if (type === TIPO_DOCUMENTO.ORCAMENTO) {
      url = endpointOrcamento;
    } else {
      url = endpointDocumentoSaida;
    }

    try {
      const { data: { items } } = await api.get(url);
      if (items.length === 0) return [];

      const documentos = await Promise.all(items.map(({ id }) => buscarDocumentoPorId(id)));
      return documentos.map(x => ({ ...x, isOffline: false }));
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  // Função auxiliar para buscar documentos nos terminais
  async function buscarDocumentosTerminais(numeroDocumento) {
    const terminais = JSON.parse(sessionStorage.getItem(APP_TERMINAIS) ?? '[]');
    if (!terminais.length) return [];

    const promises = terminais.map(terminal =>
      buscarDocumentoTerminal(numeroDocumento, terminal)
    );

    const resultados = await Promise.allSettled(promises);
    return resultados.flatMap((resultado, index) =>
      resultado.status === 'fulfilled' ? adicionarInformacaoTerminal(resultado.value, terminais[index]) : []
    );
  }

  // Função auxiliar para buscar um documento por ID
  async function buscarDocumentoPorId(id) {
    const endpointPedido = `${API_BASE_URL}/v1/faturamento/pedido/${id}`;
    const endpointDocumentoSaida = `${API_NODE_URL}/faturamento/documento-saida/pdv/${id}/?empresa=${filtroFilial}`;
    const endpointOrcamento = `${API_BASE_URL}/v1/faturamento/orcamento/${id}`;

    let url;
    if (type === TIPO_DOCUMENTO.PEDIDO) {
      url = endpointPedido;
    } else if (type === TIPO_DOCUMENTO.ORCAMENTO) {
      url = endpointOrcamento;
    } else {
      url = endpointDocumentoSaida;
    }

    const { data } = await api.get(url);
    return data;
  }

  // Função auxiliar para buscar documento em um terminal específico
  async function buscarDocumentoTerminal(numeroDocumento, terminal) {
    const endpointPedido = `http://${terminal.ipLocalTerminal}:3335/api/faturamento/pedido/query/${numeroDocumento}`;
    const endpointOrcamento = `http://${terminal.ipLocalTerminal}:3335/api/faturamento/orcamento/query/${numeroDocumento}`;
    const url = type === TIPO_DOCUMENTO.PEDIDO ? endpointPedido : endpointOrcamento;

    try {
      const { data } = await api.get(url);
      return data.map(x => ({ ...x, isOffline: true, terminal: terminal.identificacaoTerminal }));
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  // Função auxiliar para adicionar informações do terminal
  function adicionarInformacaoTerminal(documentos, terminal) {
    return documentos.map(doc => ({
      ...doc,
      terminal: terminal.identificacaoTerminal,
      isOffline: true
    }));
  }

  // Função principal refatorada
  const handleSearch = async event => {
    if (event.key !== 'Enter') return;

    const numeroDocumento = event.target.value;
    if (numeroDocumento === '') {
      return loadData();
    }

    setLoading(true);
    try {
      const documentosOnline = await buscarDocumentosOnline(numeroDocumento);
      const documentosTerminais = await buscarDocumentosTerminais(numeroDocumento);

      setDocumentos([...documentosTerminais, ...documentosOnline]);
    } catch (error) {
      console.error(error);
      dispatch({ type: APP_ERROR, error });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = item => {
    if (type === TIPO_DOCUMENTO.ORCAMENTO) {
      importarOrcamento([item]);
    } else if (type === TIPO_DOCUMENTO.PEDIDO) {
      importarPedido([item]);
    } else if (type === TIPO_DOCUMENTO.DOCUMENTOSAIDA) {
      importarDocumento([item]);
    }
  };

  const handleSubmitAllDocumentos = () => {
    if (type === TIPO_DOCUMENTO.ORCAMENTO) {
      // importarOrcamento(selectedRows);
      importarOrcamento(documentos.filter(item => selectedRows.includes(item?._id ?? item?.id)));
    } else if (type === TIPO_DOCUMENTO.PEDIDO) {
      // importarPedido(selectedAllRows);
      importarPedido(documentos.filter(item => selectedRows.includes(item?._id ?? item?.id)));
    } else if (type === TIPO_DOCUMENTO.DOCUMENTOSAIDA) {
      importarDocumento(documentos.filter(item => selectedRows.includes(item?._id ?? item?.id)));
    }
  };

  useEffect(() => {
    // if (!terminais) {
    //   const _terminais = JSON.parse(sessionStorage.getItem(APP_TERMINAIS) ?? '[]');
    //   if (_terminais) {
    //     _terminais.push({
    //       identificacaoTerminal: terminal,
    //       ipLocalTerminal: `${API_LOCAL_URL}`?.replace('http://', '')?.replace(':3335/api', '')
    //     });
    //   }

    //   setTerminais(_terminais);
    // }

    loadData();
  }, [filtroData, filtroFilial]);

  return (
    <Mui.Dialog open={show} onClose={onClose} PaperComponent={Paper} fullScreen>
      <Mui.DialogTitle>
        <Mui.AppBar color="default" position="fixed">
          <Mui.Toolbar>
            <Mui.IconButton color="inherit" onClick={onClose} aria-label="close" tabIndex={-1}>
              <MuiIcons.ArrowBack />
            </Mui.IconButton>
            <Mui.Box style={{ width: 320, paddingRight: 16 }}>
              <Mui.Typography variant="h5" style={{ textTransform: 'upperCase' }}>
                {getTitle()}
              </Mui.Typography>
            </Mui.Box>

            <Mui.Box flexGrow={1} />
            <Mui.TextField
              disabled={loading}
              InputProps={{
                style: {
                  height: 44
                },
                startAdornment: (
                  <Mui.InputAdornment position="start">
                    <Mui.SvgIcon fontSize="small" color="action">
                      <MuiIcons.Search />
                    </Mui.SvgIcon>
                  </Mui.InputAdornment>
                )
              }}
              fullWidth
              autoFocus
              placeholder="Pesquisar documento..."
              variant="outlined"
              onKeyDown={event => handleSearch(event)}
            />

            {type === TIPO_DOCUMENTO.DOCUMENTOSAIDA && (
              <Mui.Select
                variant="outlined"
                style={{ width: 290, height: 44, marginLeft: 8 }}
                value={filtroFilial}
                onChange={e => setFiltroFilial(e.target.value)}
              >
                {filtroFiliaisOptions?.map((item, index) => (
                  <Mui.MenuItem key={index} value={item.filtro}>
                    {item.titulo}
                  </Mui.MenuItem>
                ))}
              </Mui.Select>
            )}

            <Mui.Select
              variant="outlined"
              style={{ width: 290, height: 44, marginLeft: 8 }}
              value={filtroData}
              onChange={e => setFiltroData(e.target.value)}
            >
              {filtroDataOptions?.map((item, index) => (
                <Mui.MenuItem key={index} value={item.filtro}>
                  {item.titulo}
                </Mui.MenuItem>
              ))}
            </Mui.Select>

            {ultimosDocumentos?.length > 0 && (
              <Mui.Button
                variant="outlined"
                style={{ marginLeft: 8 }}
                onClick={() => {
                  setDocumentos([...ultimosDocumentos]);
                }}
              >
                <Mui.Badge color="error" badgeContent={ultimosDocumentos?.length} />
                <br />
                <Mui.Typography>Últimos documentos</Mui.Typography>
              </Mui.Button>
            )}
          </Mui.Toolbar>
        </Mui.AppBar>
      </Mui.DialogTitle>
      <Mui.DialogContent dividers>
        {loading ? (
          <>
            <Mui.Box style={{ marginTop: 64 }}>
              <Mui.LinearProgress variant="indeterminate" color="primary" />
            </Mui.Box>
            <Searching />
          </>
        ) : (
          <>
            {documentos?.length > 0 ? (
              <>
                <Mui.Table style={{ marginTop: 64 }}>
                  <Mui.TableHead>
                    <Mui.TableCell align="center" style={{ width: 44 }}>
                      <Mui.Checkbox
                        checked={selectedAllRows}
                        color="primary"
                        indeterminate={selectedSomeRows}
                        onChange={handleSelectAllDocumentos}
                      />
                    </Mui.TableCell>
                    {columns.map((column, index) => (
                      <Mui.TableCell
                        key={`column-${index}`}
                        align={column?.alignment ?? 'left'}
                        style={{ width: column?.width ? `${column?.width}px` : 'auto' }}
                      >
                        {column?.label}
                      </Mui.TableCell>
                    ))}
                    <Mui.TableCell align="center" style={{ width: '160px' }}>
                      #
                    </Mui.TableCell>
                  </Mui.TableHead>
                  <Mui.TableBody>
                    {documentos?.map((item, index) => {
                      const isSelected = selectedRows.includes(item?._id ?? item?.id);

                      return (
                        <Mui.TableRow key={`documento-${index}`}>
                          <Mui.TableCell align="center" style={{ width: 44 }}>
                            <Mui.Checkbox
                              checked={isSelected}
                              color="primary"
                              value={isSelected}
                              onChange={event =>
                                handleSelectDocumento(event, item?._id ?? item?.id)
                              }
                            />
                          </Mui.TableCell>
                          <Mui.TableCell>
                            {item?._terminal_importacao?.identificacaoTerminal}
                          </Mui.TableCell>
                          <Mui.TableCell>
                            {item?.serie ?? item?.documentoSerie}/
                            {item?.numero ?? item?.documentoNumero}
                          </Mui.TableCell>
                          <Mui.TableCell>
                            <Mui.Typography variant="h6">
                              {item?.dataEmissao
                                ? formatDate(new Date(item?.dataEmissao))
                                : formatDate(new Date(item?.documentoDataEmissao))}
                            </Mui.Typography>
                          </Mui.TableCell>
                          <Mui.TableCell>
                            <Mui.Typography variant="h6" noWrap>
                              {item?.clienteNome ?? '-'}
                            </Mui.Typography>
                          </Mui.TableCell>
                          <Mui.TableCell>
                            <Mui.Typography variant="h6" noWrap>
                              {item?.fisOperacao?.descricao ?? '-'}
                            </Mui.Typography>
                          </Mui.TableCell>
                          <Mui.TableCell>
                            <Mui.Typography variant="h6" noWrap>
                              {item?.fatCondicaopagamento?.descricao} <br />
                            </Mui.Typography>
                            <Mui.Typography variant="caption" noWrap>
                              {item?.parcelas?.map(x => x?.finMeiopagamento?.descricao)?.join(',')}
                            </Mui.Typography>
                          </Mui.TableCell>
                          <Mui.TableCell>
                            <Mui.Typography variant="h6">{item?.fatVendedor?.nome}</Mui.Typography>
                          </Mui.TableCell>
                          <Mui.TableCell align="right">
                            <Mui.Typography variant="h6">
                              {formatCurrency(item?.valorTotal)}
                            </Mui.Typography>
                          </Mui.TableCell>
                          <Mui.TableCell>
                            <Mui.Button
                              variant="contained"
                              className="button-success"
                              style={{ marginLeft: 8, marginRight: 16 }}
                              onClick={() => handleSubmit(item)}
                            >
                              <MuiIcons.CheckCircle />
                              <span style={{ marginLeft: 8 }}>Importar</span>
                            </Mui.Button>
                          </Mui.TableCell>
                        </Mui.TableRow>
                      );
                    })}
                  </Mui.TableBody>
                </Mui.Table>
                <Mui.TablePagination
                  labelRowsPerPage={'Registros por página'}
                  rowsPerPageOptions={rowsPerPageOptions}
                  component="div"
                  count={totalDocs ?? 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            ) : (
              <ListEmpty />
            )}
          </>
        )}
      </Mui.DialogContent>
      {!loading && (
        <Mui.DialogActions>
          <Mui.Box flex={1} display="flex" justifyContent="flex-end">
            <Mui.Box>
              {selectedRows?.length > 0 && (
                <Mui.Button
                  variant="contained"
                  className="button-success"
                  onClick={() => handleSubmitAllDocumentos()}
                >
                  <MuiIcons.CheckCircle />
                  <Mui.Typography variant="span" style={{ marginLeft: 8 }}>
                    {getTitleButtonSubmit()}
                  </Mui.Typography>
                </Mui.Button>
              )}
            </Mui.Box>
            <Mui.Button size="large" style={{ marginLeft: 8 }} onClick={onClose}>
              CANCELAR (Esc)
            </Mui.Button>
          </Mui.Box>
        </Mui.DialogActions>
      )}
    </Mui.Dialog>
  );
};

export default ModalImportarDocumento;
