import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { green, blue } from '@material-ui/core/colors';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

import api from '~/services/api';
import history from '~/services/history';
import toast from '~/services/toast';
import * as monitorActions from '~/store/modules/monitor/monitor-actions';
import { getApplicationIsOnline } from '~/store/modules/app/app-selectors';
import { formatCurrency, formatDate } from '~/utils/format';
import {
  API_BASE_URL,
  API_LOCAL_URL,
  API_TERMINAL_URL,
  API_VENDAS_URL,
  APP_ERROR,
  DOCUMENTO_FINALIDADE_ENUM,
  DOCUMENTO_TRANSMISSAO,
  DOCUMENTO_TRANSMISSAO_ENUM,
  MODELO_DOCUMENTO,
  TIPO_DOCUMENTO,
  STATUS_MONITOR_NFCE
} from '~/constants';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  badgeOffline: {
    backgroundColor: '#ccc',
    color: 'white'
  },
  badgeOnline: {
    backgroundColor: '#4caf50',
    color: 'white'
  },
  buttonPrimary: {
    backgroundColor: blue[500],
    '&:hover': {
      backgroundColor: blue[700]
    }
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700]
    }
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -4,
    left: -4,
    zIndex: 1
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));
import Label from '~/components/Label';
import ModalVisualizarDocumento from '../../modal/ModalVisualizarDocumento';
import ModalJustificativaCancelamento from '../../modal/ModalJustificativaCancelamento';
import { Divider } from '@mui/material';
import ModalImprimirDocSaida from '~/pages/TelaVenda/modal/ModalImprimirDocSaida';
import { selectorRotina } from '~/store/modules/auth/auth-selectors';

const DocumentosaidaTableRow = React.memo(({ data, isSelected, onRefresh, ...props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const bloqueaReimpressao = useSelector(selectorRotina('F150DSA'));

  const isOnline = useSelector(getApplicationIsOnline());
  const { status, sendingRows } = useSelector(state => state.monitor);
  const { terminal } = useSelector(state => state.app);
  const { empresa } = useSelector(state => state?.auth);

  const [showModalImpressao, setShowModalImpressao] = useState(false);
  const [cienteMulta, setCienteMulta] = useState(false);
  const [showModalJustificativaCancelamento, setShowModalJustificativaCancelamento] = useState({
    show: false,
    data: null,
    id: null
  });
  const [showModalVisualizarDocumento, setShowModalVisualizarDocumento] = useState({
    show: false,
    data: null,
    id: null
  });

  const [anchorEl, setAnchorEl] = useState(null);

  const handleEnviarDocumento = () => {
    dispatch(monitorActions.sendDocumentoRequest(data));
  };

  const handleEditarDocumento = data => {
    setShowModalVisualizarDocumento({ show: true, data });
  };

  const handleVisualizarDocumento = data => {
    setShowModalVisualizarDocumento({ show: true, readonly: true, data });
  };

  const handleSelectOneOrder = (event, orderId) => {
    dispatch(monitorActions.selectOneRow(orderId));
  };

  const handleTransmitir = async documento => {
    dispatch(
      monitorActions.transmitirDocumento(
        { ...documento },
        {
          tentativa: 1
        },
        () => {
          dispatch(monitorActions.loadDocumentosRequest());
        }
      )
    );
  };

  const handleDownloadXml = async documento => {
    dispatch(monitorActions.downloadXml(documento));
  };

  const handleDownloadDanfe = async documento => {
    dispatch(monitorActions.downloadDanfe(documento));
  };

  const handleImprimirDocumento = async documento => {
    if (bloqueaReimpressao && documento.documentoImpresso) {
      return toast.info("Parâmetro F150DSA ativo. Sem permissão para reimpressão")
    }
    const toastInstance = toast.loading(`Carregando, aguarde...`);
    try {
      const { data: venda } = await api.get(
        documento?.id !== null
          ? `${API_BASE_URL}/v1/faturamento/documentosaida/${documento?.id}`
          : `${API_LOCAL_URL}/faturamento/documentosaida/${documento?._id}`
      );

      dispatch(
        monitorActions.printDocumento(TIPO_DOCUMENTO.DOCUMENTOSAIDA, {
          ...venda,
          isOffline: documento?.id === null
        })
      );
    } catch (error) {
      dispatch({ type: APP_ERROR, error });
    } finally {
      toastInstance.close();
    }
  };

  const handleCancelarDocumento = async documento => {
    try {
      if (documento?.documentoCancelado) {
        toast.error('Não pode cancelar documento já cancelado!');
        return;
      }

      setCienteMulta(false);
      if (
        documento?.documentoModelo === MODELO_DOCUMENTO.NFE ||
        documento?.documentoModelo === MODELO_DOCUMENTO.NFCE
      ) {
        if (documento?.documentoTransmissao === DOCUMENTO_TRANSMISSAO.NAO_TRANSMITIDO) {
          setShowModalJustificativaCancelamento({
            show: true,
            title: 'Informe o motivo da inutilização, antes de cancelar o documento.',
            callback: async justificativa => {
              try {
                const dataAtual = new Date();

                const payload = {
                  ano: dataAtual.getFullYear(),
                  uf: empresa?.uf,
                  cnpj: empresa?.cnpj,
                  modelo: documento?.fisOperacao?.documentoModelo,
                  serie: documento?.documentoSerie,
                  numeroInicial: documento?.documentoNumero,
                  numeroFinal: documento?.documentoNumero,
                  justificativa,
                  cancelarDocumento: true,
                  id: documento?.id
                };

                await api.post(
                  `${API_BASE_URL}/v1/faturamento/documentosaida/inutilizar/`,
                  payload
                );

                await cancelaDocumento(justificativa, documento);
              } catch (error) {
                dispatch({ type: APP_ERROR, error });
              }
            }
          });
        } else if (documento?.documentoTransmissao === DOCUMENTO_TRANSMISSAO.TRANSMITIDO) {
          setShowModalJustificativaCancelamento({
            show: true,
            callback: async justificativa => {
              const toastInstance = toast.loading('Cancelando, aguarde ...');
              try {
                await api.put(
                  `${API_BASE_URL}/v1/faturamento/documentosaida/transmissao/cancelamento/${documento?.id}`,
                  {
                    justificativa,
                    cienteMulta
                  }
                );
                toastInstance.close();

                toast.success('Cancelamento registrado na SEFAZ com sucesso!');

                await cancelaDocumento(justificativa, documento);
              } catch (error) {
                dispatch({ type: APP_ERROR, error });
              }
            }
          });
        } else {
          setShowModalJustificativaCancelamento({
            show: true,
            callback: async justificativa => {
              await cancelaDocumento(justificativa, documento);
            }
          });
        }
      } else {
        setShowModalJustificativaCancelamento({
          show: true,
          callback: async justificativa => {
            await cancelaDocumento(justificativa, documento);
          }
        });
      }
    } catch (error) {
      dispatch({ type: APP_ERROR, error });
    }
  };

  const handleImportarDocumento = () => {};

  const handleOpenMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleGerarXml = async documentoId => {
    try {
      try {
        const { data: documento } = await api.get(
          `${API_BASE_URL}/V1/faturamento/documentosaida/${documentoId}`
        );

        await api.post(`${API_TERMINAL_URL}/documentosaida/gerar-xml`, {
          ...documento
        });
      } catch (error) {
        return;
      }
    } catch (error) {}
  };

  const cancelaDocumento = async (justificativa, documento) => {
    try {
      await api.put(
        `${API_VENDAS_URL}/v1/faturamento/documentosaida/cancelamento/${documento?.id}`,
        {
          cienteMulta,
          justificativa,
          emitidoWeb: true
        }
      );

      toast.success('Documento cancelado com sucesso!');

      dispatch(monitorActions.loadDocumentosRequest());
    } catch (error) {
      if (error?.response?.data?.[0]?.codigo === 'FAT0007') {
        Swal.fire({
          title: error?.response?.data?.[0]?.motivo,
          text: error?.response?.data?.[0]?.solucao,
          type: 'warning',
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonClass: 'md-button md-raised md-accent',
          cancelButtonClass: 'md-button md-raised',
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar'
        }).then(function() {
          setCienteMulta(true);
          cancelaDocumento(justificativa, documento);
        });
      } else {
        dispatch({ type: APP_ERROR, error });
      }
    }
  };

  return (
    <>
      <Mui.TableRow hover {...props} height={48}>
        <Mui.TableCell align="center">
          {data?.id ? (
            <Mui.Tooltip title="Online">
              <Mui.Badge
                classes={{ badge: classes.badgeOnline }}
                overlap="circular"
                badgeContent={''}
              />
            </Mui.Tooltip>
          ) : (
            <Mui.Tooltip title="Offline">
              <Mui.Badge
                classes={{ badge: classes.badgeOffline }}
                overlap="circular"
                badgeContent={''}
              />
            </Mui.Tooltip>
          )}
        </Mui.TableCell>
        <Mui.TableCell>
          <Mui.Typography variant="h6">{data?.documentoModelo}</Mui.Typography>
        </Mui.TableCell>
        <Mui.TableCell>
          <Mui.Typography variant="h6">
            {data?.documentoSerie}/{data?.documentoNumero}
          </Mui.Typography>
        </Mui.TableCell>
        <Mui.TableCell>
          <Mui.Typography variant="h6">
            {formatDate(new Date(data?.documentoDataEmissao))}
          </Mui.Typography>
        </Mui.TableCell>
        <Mui.TableCell>
          <Label color="grey">{DOCUMENTO_FINALIDADE_ENUM[data?.documentoFinalidade] ?? '-'}</Label>
        </Mui.TableCell>
        <Mui.TableCell>
          <Label
            color={
              data?.documentoTransmissao === DOCUMENTO_TRANSMISSAO.TRANSMITIDO ? 'green' : 'grey'
            }
          >
            {DOCUMENTO_TRANSMISSAO_ENUM[data?.documentoTransmissao] ?? '-'}
          </Label>
        </Mui.TableCell>

        <Mui.TableCell>
          <Mui.Typography variant="h6">
            <strong>{data?.clienteNome ?? '-'}</strong>
          </Mui.Typography>
          <Mui.Typography variant="h6">{data?.clienteCpfCnpj}</Mui.Typography>
        </Mui.TableCell>
        <Mui.TableCell>
          <Mui.Typography variant="h6">{data?.fatVendedor?.nome}</Mui.Typography>
        </Mui.TableCell>
        <Mui.TableCell align="right">
          <Mui.Typography variant="h6">{formatCurrency(data?.valorTotal)}</Mui.Typography>
        </Mui.TableCell>

        <Mui.TableCell align="right" style={{ width: 140 }}>
          <Mui.Box display="flex" flexDirection="row" justifyContent="flex-end">
            {data?.isOffline && !data?.id && isOnline && (
              <div className={classes.root}>
                <div className={classes.wrapper}>
                  <Mui.IconButton
                    size="small"
                    disabled={sendingRows && sendingRows[data?._id]?.loading}
                    onClick={() => handleEnviarDocumento()}
                  >
                    <>
                      {sendingRows && sendingRows[data?._id]?.loading ? (
                        <>
                          <MuiIcons.CloudUpload />
                          <CircularProgress size={38} className={classes.fabProgress} />
                        </>
                      ) : (
                        <MuiIcons.CloudUpload />
                      )}
                    </>
                  </Mui.IconButton>
                </div>
              </div>
            )}

            <Mui.IconButton onClick={() => handleImprimirDocumento(data)}>
              <MuiIcons.Print fontSize="small" />
            </Mui.IconButton>

            <Mui.Tooltip title="Mais ações">
              <Mui.IconButton
                disabled={terminal === null}
                aria-controls="simple-menu"
                aria-haspopup="true"
                aria-label={'Mais ações'}
                onClick={handleOpenMenu}
              >
                <MuiIcons.MoreVert />
              </Mui.IconButton>
            </Mui.Tooltip>

            <Mui.Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
            >
              <Mui.MenuItem onClick={() => {
                if (bloqueaReimpressao && data.documentoImpresso) {
                  return toast.info("Parâmetro F150DSA ativo. Sem permissão para reimpressão")
                } else {
                  setShowModalImpressao(true)
                }
              }}>
                <MuiIcons.Print fontSize="small" /> <span style={{ marginLeft: 12 }}> Imprimir</span>
              </Mui.MenuItem>
              <Divider />
              {data?.documentoModelo === MODELO_DOCUMENTO.NFCE &&
              data?.documentoTransmissao === DOCUMENTO_TRANSMISSAO.AGUARDANDO_TRANSMISSAO ? (
                <Mui.MenuItem onClick={() => handleEditarDocumento(data)}>
                  <MuiIcons.Edit fontSize="small" /> <span style={{ marginLeft: 12 }}>Editar</span>
                </Mui.MenuItem>
              ) : (
                <Mui.MenuItem onClick={() => handleVisualizarDocumento(data)}>
                  <MuiIcons.OpenInNew fontSize="small" />
                  <span style={{ marginLeft: 12 }}>Visualizar</span>
                </Mui.MenuItem>
              )}
              {(data?.documentoModelo === MODELO_DOCUMENTO.NFE ||
                data?.documentoModelo === MODELO_DOCUMENTO.NFCE) &&
              data?.nfeChave !== null &&
              data?.documentoTransmissao === DOCUMENTO_TRANSMISSAO.NAO_TRANSMITIDO ? (
                <Mui.MenuItem onClick={() => handleTransmitir(data)}>
                  <MuiIcons.CloudUpload fontSize="small" />{' '}
                  <span style={{ marginLeft: 12 }}>Transmitir</span>
                </Mui.MenuItem>
              ) : null}

              {!data?.documentoCancelado &&
                data?.documentoTransmissao === DOCUMENTO_TRANSMISSAO.NAO_TRANSMITIDO && (
                  <Mui.MenuItem onClick={() => handleCancelarDocumento(data)}>
                    <MuiIcons.Cancel fontSize="small" />{' '}
                    <span style={{ marginLeft: 12 }}>Cancelar</span>
                  </Mui.MenuItem>
                )}

              <Mui.Divider></Mui.Divider>
              {data?.documentoModelo === MODELO_DOCUMENTO.NFCE && data?.nfeChave !== null ? (
                <Mui.MenuItem onClick={() => handleDownloadDanfe(data)}>
                  <MuiIcons.CloudDownload fontSize="small" />{' '}
                  <span style={{ marginLeft: 12 }}>Download danfe</span>
                </Mui.MenuItem>
              ) : null}
              {data?.documentoModelo === MODELO_DOCUMENTO.NFCE && data?.nfeChave !== null ? (
                <Mui.MenuItem onClick={() => handleDownloadXml(data)}>
                  <MuiIcons.CloudDownload fontSize="small" />{' '}
                  <span style={{ marginLeft: 12 }}>Download xml</span>
                </Mui.MenuItem>
              ) : null}
            </Mui.Menu>
          </Mui.Box>
        </Mui.TableCell>
      </Mui.TableRow>

      { showModalImpressao &&
        <ModalImprimirDocSaida 
          open={showModalImpressao}
          docSaida={data}
          onClose={() => {
            setShowModalImpressao(false);
          }}
        />
      }

      {showModalVisualizarDocumento?.show && (
        <ModalVisualizarDocumento
          data={{
            documento: showModalVisualizarDocumento?.data,
            documentoId:
              showModalVisualizarDocumento?.data?.id ?? showModalVisualizarDocumento?.data?._id,
            isDocumentoOffline:
              showModalVisualizarDocumento?.data?.isOffline &&
              !showModalVisualizarDocumento?.data?.id
          }}
          readonly={showModalVisualizarDocumento?.readonly ?? false}
          showModal={showModalVisualizarDocumento?.show}
          onClose={() => {
            setShowModalVisualizarDocumento({
              show: false,
              data: { documentoId: null, isDocumentoOffline: null }
            });
          }}
        />
      )}

      {showModalJustificativaCancelamento?.show && (
        <ModalJustificativaCancelamento
          title={showModalJustificativaCancelamento?.title}
          showModal={showModalJustificativaCancelamento.show}
          data={showModalJustificativaCancelamento.data}
          onClose={() => {
            setShowModalJustificativaCancelamento({ show: false });
          }}
          callback={showModalJustificativaCancelamento?.callback}
        />
      )}
    </>
  );
});

export default DocumentosaidaTableRow;
