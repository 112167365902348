import React from 'react';
import { formatCurrency } from '../../../../../utils/format';

const ItensTable = ({ itens }) => (
  <div style={{ border: '1px solid black', padding: '20px', borderTop: 'none' }}>
    <div style={{ fontSize: '13px', fontWeight: '700', paddingBottom: '10px' }}>
      <strong className="color-black">Itens</strong>
    </div>
    <table className="table table-borderless table-striped" style={{ width: '100%', whiteSpace: 'nowrap' }}>
      <thead className="color-black" style={{ fontSize: '12px' }}>
        <tr>
          <th className="tabela-produtos-index" style={{ textAlign: 'start', padding: 0 }}>#</th>
          <th className="titulo-tabela-produtos" style={{ textAlign: 'start' }}>Cód.</th>
          <th className="titulo-tabela-produtos" style={{ textAlign: 'start' }}>Descrição</th>
          <th className="titulo-tabela-produtos" style={{ textAlign: 'start' }}>Unid</th>
          <th className="titulo-tabela-produtos" style={{ textAlign: 'center' }}>Qtd</th>
          <th className="titulo-tabela-produtos" style={{ textAlign: 'right' }}>Pr.Bruto</th>
          <th className="titulo-tabela-produtos" style={{ textAlign: 'right' }}>Desc.</th>
          <th className="titulo-tabela-produtos" style={{ textAlign: 'right' }}>Acrésc.</th>
          <th className="titulo-tabela-produtos" style={{ textAlign: 'right' }}>Pr.Líquido.</th>
          <th className="titulo-tabela-produtos" style={{ textAlign: 'right' }}>Total</th>
        </tr>
      </thead>

      <tbody>
        {itens?.map((item, index) => (
          <tr style={{ fontSize: '12px' }} key={item.id || index}>
            <td className="tabela-produtos-index" style={{ padding: 0 }}>{index + 1}</td>
            <td className="tabela-produtos-index" style={{ fontSize: '11px' }}>{item.produtoCodigo}</td>
            <td className="tabela-produtos-index" style={{ fontSize: '11px' }}>{item.produtoDescricao}</td>
            <td className="tabela-produtos-index" style={{ textAlign: 'start', fontSize: '11px' }}>{item.produtoUnidadeComercial}</td>
            <td className="tabela-produtos-index" style={{ textAlign: 'center' }}>{item.itemQuantidade}</td>
            <td className="tabela-produtos-index" style={{ textAlign: 'right' }}>{formatCurrency(item.itemValorUnitario)}</td>
            <td className="tabela-produtos-index" style={{ textAlign: 'right' }}>{formatCurrency(item.itemValorDesconto)}</td>
            <td className="tabela-produtos-index" style={{ textAlign: 'right' }}>{formatCurrency(item.itemValorAcrescimo)}</td>
            <td className="tabela-produtos-index" style={{ textAlign: 'right' }}>{formatCurrency(item.itemValorTotal / item.itemQuantidade)}</td>
            <td className="tabela-produtos-index" style={{ textAlign: 'right' }}>{formatCurrency(item.itemValorTotal)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

export default ItensTable;
