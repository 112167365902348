import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import styled from 'styled-components';
import Lottie from 'react-lottie';
import Paper from '@material-ui/core/Paper';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

import animationSuccess from '~/assets/animations/success.json';
import animationWarning from '~/assets/animations/warning.json';
import animationError from '~/assets/animations/error.json';
import animationPrint from '~/assets/animations/print2.json';
import animationLoading from '~/assets/animations/loading.json';
import animationUploading from '~/assets/animations/upload-to-cloud.json';

import { API_BASE_URL, API_LOCAL_URL, APP_ERROR, PDV_ACTION, TIPO_DOCUMENTO } from '~/constants';
import * as pdvActions from '~/store/modules/pdv/pdv-actions';
import * as appActions from '~/store/modules/app/app-actions';
import toast from '~/services/toast';
import api from '~/services/api';
import history from '~/services/history';

const Container = styled(Paper)`
  width: 480px;
  background-color: ${props => props.theme.palette.background.paper};
  box-shadow: ${props => props.theme.shadows[5]};
`;

const ModalLoadingSalvamento = () => {
  const dispatch = useDispatch();
  const {
    loadingSalvamento,
    loadingImpressao,
    loadingTransmissao,
    documentoSalvo,
    tipoDocumento
  } = useSelector(state => state.pdv);

  const handleClose = () => {
    dispatch(pdvActions.setShowModalLoadingSalvamento({ show: false }));
    dispatch(pdvActions.iniciarVenda({ tipoDocumento, venda: null, limparVenda: true, confirmaLogin: true }));
  };

  const handleGoToQueue = () => {
    handleClose();

    dispatch(appActions.setShowFilaDocumentos(true));
  };

  const handleEditarPedido = async data => {
    const toastInstance = toast.loading(`Carregando, aguarde...`);
    try {
      const { data: pedido } = await api.get(
        data?.isOffline
          ? `${API_LOCAL_URL}/faturamento/pedido/${data?._id}`
          : `${API_BASE_URL}/v1/faturamento/pedido/${data?.id}`
      );

      history.push('/')
      setTimeout(() => {
        history.push('/pdv', {
          tipoDocumento: TIPO_DOCUMENTO.PEDIDO,
          venda: { ...pedido, editar: true }
        });
      }, 100)
    } catch (error) {
      dispatch({ type: APP_ERROR, error });
    } finally {
      toastInstance.close();
    }
  };

  return (
    <>
      <Mui.Dialog open={true}>
        <Container>
          <Mui.Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            p={4}
          >
            {loadingSalvamento ? (
              <>
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: animationLoading,
                    rendererSettings: {
                      preserveAspectRatio: 'xMidYMid slice'
                    }
                  }}
                  height={256}
                  width={256}
                  isStopped={false}
                  isPaused={false}
                />
                <Mui.Typography variant="h4">Salvando, aguarde...</Mui.Typography>
              </>
            ) : (
              <>
                {!loadingImpressao &&
                  !loadingTransmissao &&
                  documentoSalvo?.action !== PDV_ACTION.ERRO &&
                  documentoSalvo?.action !== PDV_ACTION.OFFLINE &&
                  documentoSalvo?.action !== PDV_ACTION.BLOQUEADO && (
                    <Lottie
                      options={{
                        loop: false,
                        autoplay: true,
                        animationData: animationSuccess,
                        rendererSettings: {
                          preserveAspectRatio: 'xMidYMid slice'
                        }
                      }}
                      height={256}
                      width={256}
                      isStopped={false}
                      isPaused={false}
                    />
                  )}
                {loadingImpressao && (
                  <Lottie
                    options={{
                      loop: loadingImpressao,
                      autoplay: true,
                      animationData: animationPrint,
                      rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice'
                      }
                    }}
                    height={256}
                    width={256}
                    isStopped={false}
                    isPaused={false}
                  />
                )}
                {loadingTransmissao && (
                  <Lottie
                    options={{
                      loop: loadingTransmissao,
                      autoplay: true,
                      animationData: animationUploading,
                      rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice'
                      }
                    }}
                    height={256}
                    width={256}
                    isStopped={false}
                    isPaused={false}
                  />
                )}

                {documentoSalvo?.action === PDV_ACTION.SALVO && (
                  <>
                    <Mui.Box textAlign="center">
                      {loadingImpressao && <Mui.Typography>Imprimindo, aguarde ...</Mui.Typography>}
                      {loadingTransmissao && (
                        <Mui.Typography>Transmitindo, aguarde ...</Mui.Typography>
                      )}
                      {loadingImpressao || (loadingTransmissao && <br />)}
                      <Mui.Typography variant="h1">
                        #{documentoSalvo?.numeroDocumento}
                      </Mui.Typography>
                      <br />

                      {!loadingTransmissao &&
                        (documentoSalvo?.documento?.documentoModelo === '55' ||
                          documentoSalvo?.documento?.documentoModelo === '65') && (
                          <>
                            <br />
                            <Mui.Typography>Chave do documento:</Mui.Typography>
                            <Mui.Typography>{documentoSalvo?.documentoChave}</Mui.Typography>
                          </>
                        )}

                      {documentoSalvo?.isDocumentoOffline &&
                        !loadingImpressao &&
                        !loadingTransmissao && (
                          <>
                            <br />
                            <Alert severity="info" variant="standard">
                              <AlertTitle>
                                <b>Documento gerado offline, aguardado na fila para o envio.</b>
                              </AlertTitle>
                            </Alert>
                            <br />
                            <Mui.Button
                              fullWidth
                              variant="contained"
                              color="primary"
                              onClick={handleGoToQueue}
                            >
                              Ir para a fila
                            </Mui.Button>
                            <br />
                          </>
                        )}

                      {!loadingImpressao && !loadingTransmissao && (
                        <Mui.Box mt={2}>
                          <Mui.Button fullWidth focusRipple autoFocus onClick={handleClose}>
                            FECHAR/INICIAR VENDA
                          </Mui.Button>
                        </Mui.Box>
                      )}
                    </Mui.Box>
                  </>
                )}

                {documentoSalvo?.action === PDV_ACTION.ERRO && (
                  <>
                    {!loadingTransmissao && (
                      <Lottie
                        options={{
                          loop: false,
                          autoplay: true,
                          animationData: animationError,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice'
                          }
                        }}
                        height={256}
                        width={256}
                        isStopped={false}
                        isPaused={false}
                      />
                    )}

                    <Mui.Box style={{ padding: 32, textAlign: 'center' }}>
                      <Mui.Typography variant="h1">Erro</Mui.Typography>
                      <br />
                      <Mui.Typography>{documentoSalvo?.mensagem}</Mui.Typography>

                      <Mui.Button
                        fullWidth
                        style={{ marginTop: 16 }}
                        onClick={() =>
                          dispatch(pdvActions.setShowModalLoadingSalvamento({ show: false }))
                        }
                      >
                        VOLTAR
                      </Mui.Button>
                    </Mui.Box>
                  </>
                )}

                {documentoSalvo?.action === PDV_ACTION.BLOQUEADO && (
                  <>
                    <Lottie
                      options={{
                        loop: false,
                        autoplay: true,
                        animationData: animationWarning,
                        rendererSettings: {
                          preserveAspectRatio: 'xMidYMid slice'
                        }
                      }}
                      height={256}
                      width={256}
                      isStopped={false}
                      isPaused={false}
                    />
                    <Mui.Box style={{ padding: 32, textAlign: 'center' }}>
                      <Mui.Typography variant="h1">Atenção</Mui.Typography>

                      <br />
                      <Mui.Typography variant="h4">{documentoSalvo?.mensagem}</Mui.Typography>
                      <Mui.Button
                        variant={'contained'}
                        fullWidth
                        style={{ marginTop: 32 }}
                        onClick={() => {
                          dispatch(pdvActions.setShowModalLoadingSalvamento({ show: false }));
                          dispatch(
                            pdvActions.setShowModalLiberacaoPedido(
                              {
                                show: true,
                                id: documentoSalvo?.id,
                                bloqueio: documentoSalvo?.bloqueio,
                                isDocumentoOffline: documentoSalvo?.isDocumentoOffline
                              },
                              () => {
                                dispatch(
                                  pdvActions.iniciarVenda({
                                    tipoDocumento,
                                    venda: null
                                  })
                                );
                              }
                            )
                          );
                        }}
                      >
                        DESBLOQUEAR PEDIDO
                      </Mui.Button>
                      <Mui.Button 
                        variant={'contained'}
                        fullWidth
                        focusRipple
                        style={{ marginTop: 16 }}
                        onClick={() => handleEditarPedido(documentoSalvo?.documento)}
                      >
                        EDITAR PEDIDO
                      </Mui.Button>
                      <Mui.Button
                        fullWidth
                        focusRipple
                        autoFocus
                        style={{ marginTop: 16 }}
                        onClick={handleClose}
                      >
                        FECHAR/INICIAR VENDA
                      </Mui.Button>
                    </Mui.Box>
                  </>
                )}

                {documentoSalvo?.action === PDV_ACTION.RETENTATIVA && (
                  <>
                    <Lottie
                      options={{
                        loop: true,
                        autoplay: true,
                        animationData: animationLoading,
                        rendererSettings: {
                          preserveAspectRatio: 'xMidYMid slice'
                        }
                      }}
                      height={256}
                      width={256}
                      isStopped={false}
                      isPaused={false}
                    />
                    <Mui.Typography variant="h4">{documentoSalvo?.mensagem}</Mui.Typography>
                  </>
                )}

                {documentoSalvo?.action === PDV_ACTION.OFFLINE && (
                  <>
                    {!loadingTransmissao && (
                      <Lottie
                        options={{
                          loop: true,
                          autoplay: true,
                          animationData: animationError,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice'
                          }
                        }}
                        height={256}
                        width={256}
                        isStopped={false}
                        isPaused={false}
                      />
                    )}

                    <Mui.Typography variant="h1">#{documentoSalvo?.numeroDocumento}</Mui.Typography>
                    <br />
                    <Mui.Typography variant="h4" align="center">
                      {documentoSalvo?.mensagem}
                    </Mui.Typography>

                    <Mui.Box
                      p={1}
                      display="flex"
                      flexDirection="column"
                      flex={1}
                      width="100%"
                      marginTop={2}
                    >
                      <>
                        {!loadingTransmissao &&
                          documentoSalvo?.documento?.documentoModelo === '65' && (
                            <>
                              <Mui.Button
                                fullWidth
                                variant="contained"
                                focusRipple
                                size="large"
                                color="primary"
                                onClick={() => {
                                  dispatch(pdvActions.gerarDocumentoOffline(documentoSalvo, true));
                                }}
                              >
                                <MuiIcons.CloudDownload />
                                <Mui.Typography variant="span" style={{ marginLeft: 8 }}>
                                  Gerar Offline
                                </Mui.Typography>
                              </Mui.Button>
                              <Mui.Button
                                fullWidth
                                focusRipple
                                autoFocus
                                onClick={handleClose}
                                style={{ marginTop: 16 }}
                              >
                                FECHAR/INICIAR VENDA
                              </Mui.Button>
                            </>
                          )}
                      </>
                    </Mui.Box>
                  </>
                )}
              </>
            )}
          </Mui.Box>
        </Container>
      </Mui.Dialog>
    </>
  );
};

export default ModalLoadingSalvamento;
