import { useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useSelector } from 'react-redux';
import { DialogContent } from '@mui/material';
import { Dialog } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import DialogHeader from '../../components/Impressao/DialogHeader';
import UserInfoSection from '../../components/Impressao/UserInfoSection';
import OrcamentoIdentificationSection from '../../components/Impressao/OrcamentoIdentificationSection';
import ItensTable from '../../components/Impressao/ItensTable';
import ComplementaryInfo from '../../components/Impressao/ComplementaryInfo';
import TotalizersSection from '../../components/Impressao/TotalizersSection';
import PrintDialogActions from '../../components/Impressao/PrintDialogActions';
import ParcelasTable from '../../components/Impressao/ParcelasTable';

import '../ModalImprimirOrcamento/index.css'
import ModalCompartilharDocumento from '../ModalCompartilharDocumento';
import { API_LOCAL_URL, API_NODE_URL, TIPO_DOCUMENTO } from '~/constants';
import api from '~/services/api';
import toast from '~/services/toast';

const ModalImprimirDocSaida = ({ open, docSaida, onClose }) => {
    docSaida.itens = docSaida?.itens?.filter(item => !item.itemCancelado);
    const empresa = useSelector(state => state?.auth?.empresa ?? 0);
    const user = useSelector(state => state?.auth?.usuario ?? 0);
    const componentRef = useRef(null);
    const [modalEnviarOrcamento, setModalEnviarOrcamento] = useState(false);

    const atualizarDocumentoSaida = async () => {
      const toastInstance = toast.loading('Aguarde...');
      try {
        const payload = {
          ...docSaida,
          documentoImpresso: true,
        };

      await api.put(
          docSaida?.id !== null
              ? `${API_NODE_URL}/faturamento/documento-saida/atualizastatusimpressao/${docSaida?.id}`
              : `${API_LOCAL_URL}/faturamento/documento-saida/atualizastatusimpressao/${docSaida?._id}`,
          payload
      );
      docSaida.documentoImpresso = true;
      } catch (error) {
        console.error('Erro ao atualizar o documento:', error);
      } finally {
        toastInstance.close();
        onClose()
      }
    }
  
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
      documentTitle: `DocSaida-${empresa.razaoSocial}-${docSaida?.documentoSerie}-${docSaida?.documentoNumero}`,
      pageStyle: `
      @page {
        size: auto;
        margin: 8mm;
      }
      .color-print {
        color: #263238
      }
      @media print {
        body * {
          visibility: hidden;
        }
      #printable-content, #printable-content * {
        visibility: visible;
      }
      #printable-content {
        position: absolute;
        left: 0;
        top: 0;
      }
    }`,
    onAfterPrint: () => {
      atualizarDocumentoSaida();
    }
    });
  
    const handleShare = () => setModalEnviarOrcamento(true);
  
    return (
      <>
        <Dialog
          open={open}
          onKeyDown={key => {
            if (key.code === 'Escape') {
              onClose();
            }
          }}
          PaperComponent={Paper}
          maxWidth={'lg'}
          fullWidth={true}
        >
          <DialogHeader title="Impressão de Documento de Saída" onClose={onClose} />
          <DialogContent dividers style={{ padding: '10px' }}>
            <div id="printable-content" ref={componentRef}>{
              <>
              <UserInfoSection user={user} empresa={empresa} />
              <OrcamentoIdentificationSection documento={docSaida} title="Documento de Saída" />
              <ItensTable itens={docSaida?.itens} />
              <ParcelasTable parcelas={docSaida?.parcelas} />
              <ComplementaryInfo info={docSaida?.informacaoComplementar} />
              <TotalizersSection documento={docSaida} />
              </>
              }
            </div>
          </DialogContent>
          <PrintDialogActions onPrint={handlePrint} onClose={onClose} onShare={handleShare} />

          {modalEnviarOrcamento && (
            <ModalCompartilharDocumento
              open={modalEnviarOrcamento}
              onClose={() => setModalEnviarOrcamento(false)}
              onCloseImpressao={onClose}
              emailCliente={docSaida?.intCliente?.email}
              telefoneCliente={docSaida?.intCliente?.celular}
              documento={docSaida}
              tipoDocumento={TIPO_DOCUMENTO.DOCUMENTOSAIDA}
            />
          )}
        </Dialog>
      </>
    );
  };
  
  export default ModalImprimirDocSaida;